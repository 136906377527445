import { environment as ENV } from '../../environments/environment'

export function initGoogleAnalytics() {
  if (!ENV.GOOGLE_ANALYTICS_ID) {
    return
  }

  const script = document.createElement('script')
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${ENV.GOOGLE_ANALYTICS_ID}`
  document.body.appendChild(script)

  const script2 = document.createElement('script')
  script2.innerHTML = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', '${ENV.GOOGLE_ANALYTICS_ID}');
  `
  document.body.appendChild(script2)
}
