import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { compareWith } from '../util/compare-with'

@Component({
  selector: 'rmd-sites-select-multi-account',
  templateUrl: './rmd-sites-select-multi-account.component.html',
  styleUrls: ['./rmd-sites-select-multi-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmdSitesSelectMultiAccountComponent {
  @Input() sitesSelectService

  public compareWith = compareWith
}
