import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { RmdPermissionsService } from '../../../_shared/modules/rmd-permissions/rmd-permissions.service'
import { RmdFeaturesService } from '../../../_shared/modules/rmd-features/rmd-features.service'
import { combineLatest, Observable } from 'rxjs'
import {
  selectIsBillingPageVisible,
  selectIsFuelConsultant,
  selectIsSitesPageVisible,
} from '../../../_core/root-store/user/user.selectors'
import { map } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { AppState } from '../../../_core/root-store/root-reducers'
import { isNonProductionEnvironment } from '../../../_shared/helpers'
import { authActions } from '../../root-store/auth/auth.actions'
import { RmdFloatingMenuService } from '../rmd-floating-menu/rmd-floating-menu.service'

@Component({
  selector: 'rmd-navlist',
  templateUrl: './rmd-navlist.component.html',
  styleUrls: ['./rmd-navlist.component.scss'],
})
export class RmdNavlistComponent implements OnInit {
  @Input() variant: 'floating' | 'sidebar' = 'sidebar'

  public canSeeUsers: boolean

  public isBillingPageVisible$: Observable<boolean>
  public isNonProductionEnvironment = isNonProductionEnvironment
  public isSitesPageVisible$: Observable<boolean>
  public isLimitedMember: boolean

  @HostBinding('class.rmd-navlist') private readonly cssClass = true

  constructor(
    private store: Store<AppState>,
    private permissionsService: RmdPermissionsService,
    private floatingMenuService: RmdFloatingMenuService,
    public featuresService: RmdFeaturesService
  ) {}

  ngOnInit() {
    const fleetRole = this.permissionsService.getFleetRole()
    const isFuelConsultant = this.permissionsService.getIsFuelConsultant()
    this.isLimitedMember =
      !isFuelConsultant && fleetRole?.name === 'FLEET_SUB_ACCOUNT'

    this.canSeeUsers =
      isFuelConsultant || fleetRole.name !== 'FLEET_SUB_ACCOUNT'

    this.isBillingPageVisible$ = combineLatest([
      this.featuresService.hasFeatureAsObservable('RMD_PRICING'),
      this.featuresService.hasFeatureAsObservable('RMD_BILLING'),
      this.store.select(selectIsFuelConsultant),
      this.permissionsService.hasRoleAsObservable([
        'FLEET_COMPANY_ADMIN',
        'FLEET_COMPANY_MEMBER',
      ]),
      this.permissionsService.hasRoleAsObservable([
        'FLEET_ACCOUNT_ADMIN',
        'FLEET_ACCOUNT_MEMBER',
      ]),
      this.store.select(selectIsBillingPageVisible),
    ]).pipe(
      map(
        ([
          hasPricingFeature,
          hasBillingFeature,
          isFuelConsultant,
          hasFleetCompanyRole,
          hasFleetAccountRole,
          isBillingPageVisible,
        ]) => {
          if (
            !isFuelConsultant &&
            !(
              hasPricingFeature &&
              hasBillingFeature &&
              (hasFleetCompanyRole || hasFleetAccountRole)
            )
          ) {
            return false
          }

          return isBillingPageVisible
        }
      )
    )

    this.isSitesPageVisible$ = combineLatest([
      this.store.select(selectIsFuelConsultant),
      this.permissionsService.hasRoleAsObservable([
        'FLEET_COMPANY_ADMIN',
        'FLEET_COMPANY_MEMBER',
      ]),
      this.permissionsService.hasRoleAsObservable([
        'FLEET_ACCOUNT_ADMIN',
        'FLEET_ACCOUNT_MEMBER',
      ]),
      this.store.select(selectIsSitesPageVisible),
    ]).pipe(
      map(
        ([
          isFuelConsultant,
          hasFleetCompanyRole,
          hasFleetAccountRole,
          isSitesPageVisible,
        ]) => {
          if (
            !isFuelConsultant &&
            !(hasFleetCompanyRole || hasFleetAccountRole)
          ) {
            return false
          }

          return isSitesPageVisible
        }
      )
    )
  }

  public onNavlistItemClick(): void {
    this.floatingMenuService.close()
  }

  public onLogoutClick(): void {
    this.floatingMenuService.close()
    this.store.dispatch(authActions.LOGOUT())
  }
}
