import { Component, HostListener } from '@angular/core'
import { RmdFloatingMenuService } from '../rmd-floating-menu/rmd-floating-menu.service'

@Component({
  selector: 'rmd-floating-menu-toggle',
  templateUrl: 'rmd-floating-menu-toggle.component.html',
  styleUrls: ['rmd-floating-menu-toggle.component.scss'],
})
export class RmdFloatingMenuToggleComponent {
  @HostListener('click') private _onClick() {
    this.onNavigationToggle()
  }

  constructor(public floatingMenuService: RmdFloatingMenuService) {}

  private onNavigationToggle(): void {
    if (this.floatingMenuService.isOpen$.getValue()) {
      this.floatingMenuService.close()
    } else {
      this.floatingMenuService.open()
    }
  }
}
