import { ServiceLocation } from '../../../models/customer.models'

export function compareWith(
  serviceLocation1: ServiceLocation,
  serviceLocation2: ServiceLocation
) {
  return (
    serviceLocation1 &&
    serviceLocation2 &&
    serviceLocation1.fleetAccountId === serviceLocation2.fleetAccountId &&
    serviceLocation1.fleetCompanyId === serviceLocation2.fleetCompanyId
  )
}
