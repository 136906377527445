import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { RmdFloatingMenuService } from '../rmd-floating-menu/rmd-floating-menu.service'
import { Customer } from '@boosterfuels/types/types-ts'
import { AppState } from '../../root-store/root-reducers'
import { Store } from '@ngrx/store'
import { authActions } from '../../root-store/auth/auth.actions'

@Component({
  selector: 'rmd-header',
  templateUrl: 'rmd-header.component.html',
  styleUrls: ['rmd-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmdHeaderComponent {
  @Input() customer: Partial<Customer>
  @Input() showDropdown: boolean
  @Input() nextServiceWindow: string

  constructor(
    private store: Store<AppState>,
    private floatingMenuService: RmdFloatingMenuService
  ) {}

  public onLogoClicked(): void {
    if (this.floatingMenuService.isOpen$.getValue()) {
      this.floatingMenuService.close()
    }
  }

  public onLogoutClick(): void {
    this.store.dispatch(authActions.LOGOUT())
  }
}
