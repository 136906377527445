import { Component } from '@angular/core'
import { ActionsSubject } from '@ngrx/store'
import { ColorsService } from './_core/services/colors.service'
import { ROUTER_NAVIGATED } from '@ngrx/router-store'
import { ofType } from '@ngrx/effects'
import { first, map, Observable } from 'rxjs'

@Component({
  selector: 'app-root',
  template: `
    <app-init-loader [showLoader]="!(hideLoader$ | async)"></app-init-loader>
    <router-outlet></router-outlet>
  `,
})
export class AppComponent {
  public hideLoader$: Observable<boolean>

  constructor(
    private colorsService: ColorsService,
    private actionsSubject: ActionsSubject
  ) {
    this.colorsService.init()

    this.hideLoader$ = this.actionsSubject
      .pipe(ofType(ROUTER_NAVIGATED))
      .pipe(first())
      .pipe(map(() => true))
  }
}
