<ng-container *ngrxLet="onboardingFleetAccounts$ as onboardingFleetAccounts">
  <div class="banner-notifications" *ngIf="onboardingFleetAccounts.length">
    <div class="banner-notifications-inner">
      <bui-message variant="warning" class="banner-notifications__onboarding">
        <p *ngrxLet="url$ as url">
          <ng-container
            *ngIf="onboardingFleetAccounts.length > 1; else singleOnboarding"
          >
            <strong>
              You have {{ onboardingFleetAccounts.length }} sites that cannot be
              serviced until their onboarding is complete.
            </strong>
            <a
              *ngIf="!isOnPage(['/sites'], url, ['/sites/'])"
              [routerLink]="['/sites']"
            >
              View sites
            </a>
          </ng-container>

          <ng-template #singleOnboarding>
            <strong>
              We cannot service your site until your set up is complete.
            </strong>
            <a
              *ngIf="
                !isOnPage(
                  ['/sites', onboardingFleetAccounts[0]._id, 'onboarding'],
                  url
                )
              "
              [routerLink]="[
                '/sites',
                onboardingFleetAccounts[0]._id,
                'onboarding'
              ]"
            >
              Go to onboarding
            </a>
          </ng-template>
        </p>
      </bui-message>
    </div>
  </div>
</ng-container>
