import { Router, CanActivateFn, CanActivateChildFn } from '@angular/router'
import { Injectable } from '@angular/core'
import { RmdFeaturesService } from '../../../_shared/modules/rmd-features/rmd-features.service'
import { get } from 'lodash'
import { combineLatest, Observable, of } from 'rxjs'
import { take, map } from 'rxjs/operators'
import { AppState } from '../../root-store/root-reducers'
import { Store } from '@ngrx/store'

@Injectable()
export class RmdFeaturesGuard {
  constructor(
    private featuresService: RmdFeaturesService,
    private router: Router,
    private store: Store<AppState>
  ) {}

  canActivate: CanActivateFn = (route, state) => {
    const features = get(route, 'data.features')

    if (route.queryParams['acc'] && route.queryParams['inv']) {
      const trackingData = this.store.select((state) => ({
        userId: state.user.customer._id,
        customerId: state.user.customer._id,
        fleetCustomerId: state.user.fleetCustomer._id,
        invoiceIssuedForId: route.queryParams['acc'],
        invoiceNumber: route.queryParams['inv'],
      }))

      return combineLatest([trackingData, this.resolve(features)]).pipe(
        map(([trackingData, resolve]) => {
          window.analytics.track('openInvoiceFromEmail', trackingData)
          return resolve
        }),
        take(1)
      )
    } else {
      return this.resolve(features)
    }
  }

  canActivateChild: CanActivateChildFn = (route, state) => {
    const features = get(route, 'data.features')
    return this.resolve(features)
  }

  resolve(features: string[]): Observable<boolean> {
    if (features) {
      return combineLatest(
        features.map((feature) =>
          this.featuresService.hasFeatureAsObservable(feature)
        )
      ).pipe(
        take(1),
        map((hasEveryFeatureArray) => {
          // default homepage is now dashboard
          // if user doesn't have dashboard feature, redirect to services
          if (!hasEveryFeatureArray.every((hasFeature) => hasFeature)) {
            this.router.navigate(['/services'])
            return false
          }
          return true
        })
      )
    }
    return of(true)
  }
}
