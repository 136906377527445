import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpParams,
} from '@angular/common/http'

import { Observable } from 'rxjs'

@Injectable()
export class GetParamsEncoderInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method !== 'GET') {
      return next.handle(req)
    }
    const reqWithValidParams = req.clone({
      params: encodeParams(req.params),
    })

    return next.handle(reqWithValidParams)
  }
}

function encodeParams(params: HttpParams): HttpParams {
  let encodedParams: HttpParams = new HttpParams()
  params.keys().forEach((key: string) => {
    const paramValue = params.get(key)
    if ((paramValue as any) instanceof Date) {
      encodedParams = encodedParams.append(key, paramValue.toString())
      return
    }
    if (typeof paramValue === 'object') {
      encodedParams = encodedParams.append(key, JSON.stringify(paramValue))
      return
    }
    encodedParams = encodedParams.append(key, paramValue)
  })

  return encodedParams
}
