import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { BuiToastService } from '@fleet-customer/booster-ui'
import { authActions } from '../root-store/auth/auth.actions'
import { AppState } from '../root-store/root-reducers'
import * as Sentry from '@sentry/angular-ivy'

@Injectable({ providedIn: 'root' })
export class ErrorService {
  public errorSubtitle: string

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private buiToastService: BuiToastService
  ) {}

  public handleError(errorResponse: HttpErrorResponse): void {
    const error = errorResponse.error?.error
    const message = `Error ${errorResponse.status}: ${
      error?.message || 'Unexpected Error'
    }`

    switch (error.status) {
      case 401:
        if (error?.code !== 'LOGIN_FAILED') {
          this.store.dispatch(authActions.LOGOUT())
        }
        this.openToast(message)
        break
      case 403:
        this.handle403()
        break
      case 404:
        this.setErrorSubtitle(message)
        this.router.navigate(['/page-not-found'])
        break
      default:
        this.openToast(message)
    }
    Sentry.captureEvent({
      message: `${errorResponse.status}: ${error?.message}`,
      extra: error,
    })
  }

  public handle403(message?: string): void {
    this.setErrorSubtitle(message)
    this.router.navigate(['/forbidden-access'])
  }

  private openToast(message: string) {
    this.buiToastService.open({ variant: 'error', message })
  }

  private setErrorSubtitle(subtitle: string | undefined): void {
    if (subtitle === 'SIGN_UP_NOT_FINISHED') {
      this.errorSubtitle = 'Sign up not finished'
    } else if (subtitle) {
      this.errorSubtitle = this.trimEndingDot(subtitle)
    } else {
      this.errorSubtitle = undefined
    }
  }

  private trimEndingDot(text): string {
    return text.endsWith('.') ? text.substring(0, text.length - 1) : text
  }
}
