import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-init-loader',
  templateUrl: './app-init-loader.component.html',
  styleUrls: ['./app-init-loader.component.scss'],
})
export class AppInitLoaderComponent {
  @Input() showLoader = false
}
