import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ErrorService } from '../_core/services/error.service'

interface ErrorRouteData {
  title: string
  message: string
  showLogin: boolean
  showRetryLogin: boolean
}

@Component({
  selector: 'error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit {
  public routeData?: ErrorRouteData

  constructor(
    private route: ActivatedRoute,
    public errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this.routeData = this.route.snapshot.data as ErrorRouteData
  }
}
