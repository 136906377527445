<div class="layout" [class.layout--legacy]="useLegacyLayout$ | async">
  <div class="layout__sidebar">
    <rmd-sidebar></rmd-sidebar>
  </div>
  <div class="content">
    <rmd-header
      [customer]="customer$ | async"
      [nextServiceWindow]="(selectedFleetAccount$ | async)?.nextServiceWindow"
      [showDropdown]="showDropdown$ | async"
    ></rmd-header>

    <div
      class="content__outlet"
      [class.content__outlet--allow-overflow]="allowContentOverflow$ | async"
      #content
      cdkScrollable
    >
      <rmd-banner-notifications
        class="rmd-banner-notifications"
      ></rmd-banner-notifications>

      <div class="content__outlet-inner">
        <router-outlet (activate)="onRouteActivate()"></router-outlet>
      </div>
    </div>
  </div>
</div>

<rmd-floating-menu
  class="floating-nav"
  [nextServiceWindow]="(selectedFleetAccount$ | async)?.nextServiceWindow"
></rmd-floating-menu>
