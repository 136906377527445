import { CustomerApi } from './customer.api'
import { FleetCustomerApi } from './fleet-customer.api'
import { FleetEmailNotificationPreferenceApi } from './email-preferences.api'
import { FleetFeedbackApi } from './fleet-feedback.api'
import { FleetOrderApi } from './fleet-order.api'
import { ProductApi } from './product.api'
import { GeneratedServiceReportApi } from './generated-service-report-api'
import { ScheduledServiceReportApi } from './scheduled-service-report-api'
import { FleetCompanyApi } from './fleet-company.api'
import { DeliveryProcessOverviewApi } from './delivery-process-overview.api'
import { OnsiteContactApi } from './onsite-contact.api'
import { SchedulesApi } from './schedules.api'
import { FleetVehicleApi } from './fleet-vehicle.api'
import { PendingFleetVehicleApi } from './pending-fleet-vehicle.api'

export const apiServices = [
  CustomerApi,
  FleetCompanyApi,
  FleetCustomerApi,
  FleetEmailNotificationPreferenceApi,
  FleetFeedbackApi,
  FleetOrderApi,
  FleetVehicleApi,
  PendingFleetVehicleApi,
  ProductApi,
  GeneratedServiceReportApi,
  ScheduledServiceReportApi,
  DeliveryProcessOverviewApi,
  OnsiteContactApi,
  SchedulesApi,
]

export * from './interceptors/index'
