// angular
import { CommonModule, DecimalPipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'
import { RouterModule } from '@angular/router'

// modules/components
import { BuiModalModule, BuiToastModule } from '@fleet-customer/booster-ui'
import { LayoutModule } from '../_core/layout/layout.module'
import { AppInitLoaderComponent } from './app-init-loader/app-init-loader.component'

// guards
import { LocalEnvironmentGuard } from './services/guards/local-environment-guard.service'
import { FleetRoleGuard } from './services/guards/fleet-role-guard'
import { RmdFeaturesGuard } from './services/guards/rmd-features-guard.service'
import { TokenGuard } from './services/guards/token-guard.service'
import { BillingPageGuard } from './services/guards/billing-page-guard.service'
import { AuthGuard } from './services/guards/auth-guard.service'
import { NonProductionGuard } from './services/guards/non-production-environment-guard.service'

// other
import { apiServices } from './api/index'
import { httpInterceptorProviders } from './api/index'
import { AuthService } from './services/auth.service'
import { rootStoreImports } from '../_core/root-store'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    BuiModalModule,
    LayoutModule,
    BuiToastModule.forRoot(),
    ...rootStoreImports,
  ],
  providers: [
    ...apiServices,
    DecimalPipe,
    AuthService,
    AuthGuard,
    LocalEnvironmentGuard,
    NonProductionGuard,
    FleetRoleGuard,
    RmdFeaturesGuard,
    TokenGuard,
    BillingPageGuard,
    httpInterceptorProviders,
  ],
  declarations: [AppInitLoaderComponent],
  exports: [AppInitLoaderComponent],
})
export class CoreModule {}
