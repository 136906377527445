<div class="wrapper">
  <h1>{{ routeData.title }}</h1>
  <h2 *ngIf="errorService.errorSubtitle">{{ errorService.errorSubtitle }}.</h2>
  <p>{{ routeData.message }}</p>

  <p *ngIf="routeData.showLogin">
    If you already have an account, please
    <a [routerLink]="['/login']">log in</a> to continue.
  </p>

  <p *ngIf="routeData.showRetryLogin">
    Additionally, you can try to <a [routerLink]="['/login']">log in</a> again.
  </p>

  <bui-button route="/" variant="primary" size="large">Start page</bui-button>
</div>
