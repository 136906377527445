import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core'
import { AppState } from '../root-store/root-reducers'
import { Store } from '@ngrx/store'
import { selectSelectedFleetAccount } from '../root-store/user/user.selectors'
import { AuthorizedFleetAccount } from '../models/customer.models'
import { Subject } from 'rxjs'
import { filter, map, takeUntil } from 'rxjs/operators'
import { servicesIndexActions } from '../../services-page/services-index/services-index.actions'
import { EventsService } from '../services/events.service'
import { LayoutService } from './layout.service'

@Component({
  selector: 'rmd-layout',
  templateUrl: 'layout.component.html',
  styleUrls: ['layout.component.scss'],
})
export class LayoutComponent implements OnDestroy {
  @ViewChild('content') private content: ElementRef<HTMLDivElement>

  private readonly destroy$ = new Subject<void>()

  public readonly customer$ = this.store.select((s) => s.user.customer)
  public readonly showDropdown$ = this.store.select((s) => s.user.showDropdown)

  public readonly selectedFleetAccount$ = this.store
    .select(selectSelectedFleetAccount)
    .pipe(
      map(
        (selectedFleetAccount) =>
          selectedFleetAccount || ({} as AuthorizedFleetAccount)
      )
    )

  public readonly useLegacyLayout$ = this.layoutService.useLegacyLayout$

  public readonly allowContentOverflow$ =
    this.layoutService.allowContentOverflow$

  constructor(
    private store: Store<AppState>,
    private eventsService: EventsService,
    private layoutService: LayoutService
  ) {
    this.monitorNavigation()
    this.bindScrollContentOutlet()
  }

  ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  private monitorNavigation(): void {
    // dispatch reset state on services index
    // if entering a 'services' page while leaving
    // a 'non-services' page with the exception of doing
    // a 'browser/history back'
    this.eventsService.navigationStart$
      .pipe(
        filter(
          (event) =>
            event.url.includes('services') &&
            !event.originUrl.includes('services') &&
            event.navigationTrigger !== 'popstate'
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(() => this.store.dispatch(servicesIndexActions.RESET_STATE()))
  }

  private bindScrollContentOutlet(): void {
    this.eventsService.scrollContentOutlet$
      .pipe(takeUntil(this.destroy$))
      .subscribe((scrollToElement) => {
        const parentFromViewportTop =
          this.content.nativeElement?.getBoundingClientRect()?.top || 0
        const childFromViewportTop =
          scrollToElement.getBoundingClientRect()?.top || 0

        this.scrollContent(childFromViewportTop - parentFromViewportTop - 5)
      })
  }

  public onRouteActivate(): void {
    this.scrollContent(0)
  }

  private scrollContent(scrollTop: number): void {
    if (!this.content) {
      return
    }
    this.content.nativeElement.scrollTop = scrollTop
  }
}
