import { ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppComponent } from './app.component'
import { AppRoutingModule } from './app.routes'

import { CoreModule } from './_core/core.module'

// Feature modules
import { ErrorPageModule } from './error-page/error-page.module'
import {
  AVAILABLE_FLEET_ROLES,
  FLEET_ROLES,
} from './_shared/modules/rmd-permissions/config/rmd-permissions.config'

import { GlobalErrorHandler } from './_core/services/global-error-handler.service'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    ErrorPageModule,
  ],
  providers: [
    { provide: AVAILABLE_FLEET_ROLES, useValue: FLEET_ROLES },
    { provide: Window, useValue: window },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
