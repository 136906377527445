import { Action, ActionReducerMap } from '@ngrx/store'
import { InjectionToken } from '@angular/core'
export { metaReducers } from './meta-reducers'

import * as fromRouter from '@ngrx/router-store'
import * as fromLogin from './auth/auth.reducer'
import * as fromUser from './user/user.reducer'

export interface AppState {
  router: fromRouter.RouterReducerState<any>
  login: fromLogin.AuthState
  user: fromUser.State
}

export const ROOT_REDUCERS = new InjectionToken<
  ActionReducerMap<AppState, Action>
>('Root reducers token', {
  factory: () => ({
    router: fromRouter.routerReducer,
    login: fromLogin.reducer,
    user: fromUser.reducer,
  }),
})
