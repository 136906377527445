import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store'

import { ROOT_REDUCERS, metaReducers } from './root-reducers'

import { EffectsModule } from '@ngrx/effects'
import { AuthEffects } from './auth/auth.effects'
import { UserEffects } from './user/user.effects'

import { environment as ENV } from '../../../environments/environment'

export const rootStoreImports = [
  StoreModule.forRoot(ROOT_REDUCERS, {
    metaReducers,
    runtimeChecks: {
      strictStateImmutability: true,
      strictActionImmutability: true,
      strictStateSerializability: true,
      strictActionSerializability: true,
    },
  }),
  StoreRouterConnectingModule.forRoot({
    routerState: RouterState.Minimal,
  }),
  StoreDevtoolsModule.instrument({
    maxAge: 25,
    logOnly: ENV.production,
    connectInZone: true,
  }),
  EffectsModule.forRoot([AuthEffects, UserEffects]),
]
