<div class="select-wrapper">
  <bui-select
    prefix="Company:"
    data-testid="company-sites-select"
    placeholder="-"
    [typeaheadDebounceInterval]="250"
    [formControl]="sitesSelectService.selectedServiceLocation"
    [compareWith]="compareWith"
    panelPosition="start"
  >
    <bui-select-option
      *ngFor="let dropdownItem of sitesSelectService.serviceLocations"
      [disabled]="
        sitesSelectService.isOnPage(['billing']) &&
        dropdownItem.isBillingDisabled
      "
      [value]="dropdownItem"
      data-testid="company-sites-select-option"
    >
      {{ dropdownItem.displayName }}
    </bui-select-option>
  </bui-select>
</div>

<div class="select-wrapper">
  <bui-select
    prefix="Location:"
    data-testid="sites-select"
    placeholder="-"
    [typeaheadDebounceInterval]="250"
    [formControl]="sitesSelectService.selectedServiceLocation"
    [compareWith]="compareWith"
    panelPosition="start"
  >
    <bui-select-option
      *ngFor="let dropdownItem of sitesSelectService.serviceLocations"
      [disabled]="
        sitesSelectService.isOnPage(['billing']) &&
        dropdownItem.isBillingDisabled
      "
      [value]="dropdownItem"
      data-testid="sites-select-option"
    >
      {{ dropdownItem.displayName }}
    </bui-select-option>
  </bui-select>
</div>
