import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { ErrorService } from '../../../_core/services/error.service'

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorService: ErrorService) {}

  /**
   * - delagates error handling to a service
   * - returns an Observable (passes on the original error object with throwError)
   *   that can be subscribed to for component specific error handling beyond just
   *   outputting toast messages (i.e. 409 Email confilict user already exists,
   *   422 invalid form fields handling etc.)
   */

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {
        this.errorService.handleError(errorResponse)

        return throwError(() => errorResponse)
      })
    )
  }
}
