import { Component, HostBinding, Input } from '@angular/core'
import { RmdFloatingMenuService } from './rmd-floating-menu.service'
import { trigger, style, state, transition, animate } from '@angular/animations'

@Component({
  selector: 'rmd-floating-menu',
  templateUrl: 'rmd-floating-menu.component.html',
  styleUrls: ['rmd-floating-menu.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'slideIn',
        style({
          transform: 'scaleY(1)',
        })
      ),
      state(
        'slideOut',
        style({
          transform: 'scaleY(0)',
        })
      ),
      transition('slideIn <=> slideOut', [animate('100ms ease-in')]),
    ]),
  ],
})
export class RmdFloatingMenuComponent {
  constructor(private floatingMenuService: RmdFloatingMenuService) {}

  @Input() nextServiceWindow?: string

  @HostBinding('@slideInOut') get slideInOut() {
    return this.floatingMenuService.isOpen$.getValue() ? 'slideIn' : 'slideOut'
  }
}
