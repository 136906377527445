import { Component } from '@angular/core'

@Component({
  selector: 'rmd-help-button',
  template: `
    <button
      type="button"
      [buiDropdownTrigger]="helpDropdown"
      class="help-button secondary-action-button"
    >
      ?
    </button>
    <bui-dropdown #helpDropdown>
      <rmd-help-contact-block></rmd-help-contact-block>
    </bui-dropdown>
  `,
  styleUrls: [],
})
export class RmdHelpButtonComponent {}
