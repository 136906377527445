import { Injectable, ErrorHandler } from '@angular/core'
import * as Sentry from '@sentry/angular-ivy'

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  handleError(error: Error) {
    const errorMessage = error?.message || ''
    if (errorMessage.includes('Failed to fetch dynamically imported module')) {
      window.location.reload()
    } else {
      console.error(error)
      Sentry.captureException(error)
    }
  }
}
