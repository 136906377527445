// Recently (2021-03-11) we've been seeing bots crawling our site and
// triggering unsubscribe events and posting feedback. To combat that
// we tried adding robots.txt with no luck. This listener is here to
// track mouse movement so we can use this flag to prevent feedback
// if there was no mouse movement.
type voidFunc = (x: any) => void
export interface MouseMovementDetector {
  movementDetected: boolean
  waitForMouseMovement: voidFunc
  onMovementDetected: voidFunc
}

export function initMouseMovementDetector() {
  window.mouseMovementDetector = {
    movementDetected: false,
    waitForMouseMovement,
    onMovementDetected,
  }

  waitForMouseMovement(
    () => (window.mouseMovementDetector.movementDetected = true)
  )
}

function waitForMouseMovement(fn: any) {
  const myListener = () => {
    document.removeEventListener('mousemove', myListener, false)
    fn()
  }

  document.addEventListener('mousemove', myListener, false)
}

function onMovementDetected(fn: any) {
  if (window.mouseMovementDetector.movementDetected) {
    fn()
  } else {
    waitForMouseMovement(fn)
  }
}
