import { Component, Input } from '@angular/core'

@Component({
  selector: 'rmd-next-service-window',
  templateUrl: 'rmd-next-service-window.component.html',
  styleUrls: ['rmd-next-service-window.component.scss'],
})
export class RmdNextServiceWindowComponent {
  @Input() nextServiceWindow: string
}
