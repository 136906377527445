import projectInfo from '../../../../../../../package.json'
import { environment as ENV } from '../../../../environments/environment'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import {
  catchError,
  map,
  tap,
  withLatestFrom,
  exhaustMap,
  filter,
  concatMap,
} from 'rxjs/operators'
import { userActions } from './user.actions'
import { serviceDetailsActions } from '../../../services-page/service-details/store/service-details.actions'
import { billingIndexActions } from '../../../billing-page/billing-index/billing-index.actions'
import { billingDetailsActions } from '../../../billing-page/billing-details/store/billing-details.actions'
import { routerNavigatedAction } from '@ngrx/router-store'
import { FleetCustomerApi } from '../../api/fleet-customer.api'
import { ProductApi } from '../../api/product.api'
import { AuthService } from '../../../_core/services/auth.service'
import { Store } from '@ngrx/store'
import { AppStateWithUser } from './user.reducer'
import { BuiModalService } from '@fleet-customer/booster-ui'
import { UpdatesModalComponent } from '../../../updates/_shared/components/updates-modal/updates-modal.component'
import { omit } from 'lodash'
import { gte, gt } from 'semver'
import determineFleetDropdownProps from './util/determineFleetDropdownProps'
import { RmdSitesSelectService } from '../../../_core/layout/rmd-sites-select/rmd-sites-select.service'
import { QueryParamsService } from '../../../_core/services/query-params.service'
import { RmdPermissionsService } from '../../../_shared/modules/rmd-permissions/rmd-permissions.service'
import * as Sentry from '@sentry/angular-ivy'
import { PROJECT_NAME_ARMADA } from '@fleet-customer/shared/constants'
import { addDays, isAfter, parse } from 'date-fns'
import { SessionStorageService } from '@fleet-customer/shared/frontend/storage-services'
import {
  setClarityCustomUserId,
  setClarityCompanyIdTag,
  setClarityAccountIdTag,
} from '@fleet-customer/shared/frontend/third-party-scripts'

@Injectable()
export class UserEffects {
  getProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.GET_PRODUCTS),
      exhaustMap(() =>
        this.productApi.find().pipe(
          map((res) => userActions.GET_PRODUCTS_SUCCESS(res.results)),
          catchError(() => of(userActions.GET_PRODUCTS_ERROR()))
        )
      )
    )
  )

  getFleetCustomerData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.GET_FLEET_CUSTOMER_DATA),
      exhaustMap(() =>
        this.fleetCustomerApi.getData().pipe(
          map((res) => userActions.GET_FLEET_CUSTOMER_DATA_SUCCESS(res)),
          catchError(({ error }) => {
            if ([401, 403].includes(error?.error?.status)) {
              this.authService.clearAuthData()
            }

            return of(userActions.GET_FLEET_CUSTOMER_DATA_ERROR())
          })
        )
      )
    )
  )

  getFleetCustomerDataSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.GET_FLEET_CUSTOMER_DATA_SUCCESS),
      withLatestFrom(this.store, (action, state) => {
        return {
          ...action,
          router: state.router,
          updates: state.user.updates,
        }
      }),
      tap(({ fleetCustomer, customer, updates }) => {
        // this action will be the first to trigger if the user is authenticated
        // that is why we are identifying here and not on login
        const impersonatedById = this.authService.getImpersonatedById()
        if (impersonatedById) {
          this.sessionStorageService.setItem('boosterUserId', customer._id)
          this.sessionStorageService.setItem('user', customer)
        }

        if (!impersonatedById) {
          const userId = this.authService.getUserId()
          const user = this.authService.getUser()

          window.analytics.identify(userId, user)
          setClarityCustomUserId(userId)

          // show "NEW UPDATES" modal
          const projectVersionData = (
            fleetCustomer.lastSeenVersions || []
          ).find(
            (versionData) => versionData.projectName === PROJECT_NAME_ARMADA
          )
          const lastSeenVersion = projectVersionData
            ? projectVersionData.lastSeenVersion
            : '0.0.0'
          const doesNewerUpdateExist =
            (updates.length &&
              updates.findIndex((update) =>
                gt(update.releaseVersion, lastSeenVersion)
              ) > -1) ||
            updates.findIndex(
              (update) => update.releaseVersion === lastSeenVersion
            ) > 0

          if (
            gte(projectInfo.version, lastSeenVersion) &&
            doesNewerUpdateExist
          ) {
            // updates are sorted newest first
            const newestUpdateDate = parse(
              updates[0].releaseDate,
              'MM/dd/yyyy',
              new Date()
            )
            const relevantUpdateExist = isAfter(
              addDays(newestUpdateDate, 30),
              new Date()
            )

            if (relevantUpdateExist) {
              this.buiModalService.open(UpdatesModalComponent)
            }
          }
        }

        Sentry.configureScope((scope: Sentry.Scope) => {
          const user = impersonatedById ? customer : this.authService.getUser()
          scope.setUser({
            id: user._id,
            email: user.email,
          })
          if (impersonatedById) {
            scope.setContext('impersonated', { impersonatedById })
          }
        })
      }),
      map((fleetCustomerData) => {
        const { authorizedFleetAccounts, authorizedFleetCompanies, router } =
          fleetCustomerData

        let fleetAccountIdQueryParam, fleetCompanyIdQueryParam

        const { selectedFleetAccountId, selectedFleetCompanyId } =
          router.state.root.queryParams

        // check if currently redirecting (this happens on login with redirectUrl)
        const currentNavigation = this.router.getCurrentNavigation()
        if (currentNavigation) {
          fleetAccountIdQueryParam =
            currentNavigation.extractedUrl?.queryParams[
              'selectedFleetAccountId'
            ]
          fleetCompanyIdQueryParam =
            currentNavigation.extractedUrl?.queryParams[
              'selectedFleetCompanyId'
            ]
        } else {
          fleetAccountIdQueryParam = selectedFleetAccountId
          fleetCompanyIdQueryParam = selectedFleetCompanyId
        }

        let selected = null
        if (fleetCompanyIdQueryParam) {
          selected = authorizedFleetCompanies.find(
            (company) => company._id === fleetCompanyIdQueryParam
          )
        } else if (fleetAccountIdQueryParam) {
          selected = authorizedFleetAccounts.find(
            (account) => account._id === fleetAccountIdQueryParam
          )
        }

        return {
          ...fleetCustomerData,
          fleetDropdown: this.sitesSelectService.selectedServiceLocation,
          fleetAccountIdQueryParam: selected ? fleetAccountIdQueryParam : null,
          fleetCompanyIdQueryParam: selected ? fleetCompanyIdQueryParam : null,
          isFirstChange: this.sitesSelectService.isInitialSelection,
          doNotTrack: router.state.url.includes('services'),
        }
      }),
      concatMap(determineFleetDropdownProps)
    )
  )

  pageNavigated$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(routerNavigatedAction),
        withLatestFrom(this.store, (action, { user }) => ({
          action,
          selectedFleetAccountId: user.selectedFleetAccountId,
          selectedFleetCompanyId: user.selectedFleetCompanyId,
          authorizedFleetAccounts: user.authorizedFleetAccounts,
          authorizedFleetCompanies: user.authorizedFleetCompanies,
          customer: user.customer,
        })),
        tap(
          ({
            customer,
            selectedFleetAccountId,
            selectedFleetCompanyId,
            authorizedFleetAccounts,
            authorizedFleetCompanies,
          }) => {
            setClarityCustomUserId(customer?._id)
            if (
              authorizedFleetAccounts === null ||
              authorizedFleetCompanies === null
            ) {
              if (
                [
                  'login',
                  'forgot-password',
                  'reset-password',
                  'sign-up',
                  'unsubscribe',
                  'service-feedback',
                ].some((segment) => this.router.url.includes(segment)) &&
                (!this.permissionsService.hasRole('FUEL_CONSULTANT') ||
                  !ENV.production)
              ) {
                window.analytics.page('routerNavigated', {
                  customer,
                  selectedFleetAccountId,
                  selectedFleetAccountIds:
                    selectedFleetAccountId === 'ALL'
                      ? authorizedFleetAccounts
                          .filter(
                            (fleetAccount) =>
                              fleetAccount.fleetCompanyId ===
                              selectedFleetCompanyId
                          )
                          .map((fleetAccount) => fleetAccount._id)
                      : [selectedFleetAccountId],
                  selectedFleetCompanyId,
                  authorizedFleetAccounts: (authorizedFleetAccounts || []).map(
                    (fleetAccount) => fleetAccount._id
                  ),
                  authorizedFleetCompanies: (
                    authorizedFleetCompanies || []
                  ).map((fleetCompany) => fleetCompany._id),
                  type: 'PAGE_NAVIGATED',
                })
              }
              return
            }
            const fleetDropdown =
              this.sitesSelectService.selectedServiceLocation
            if (fleetDropdown.disabled) {
              fleetDropdown.enable({ emitEvent: false })
            }
            if (
              !this.permissionsService.hasRole([
                'FLEET_COMPANY_ADMIN',
                'FLEET_COMPANY_MEMBER',
              ]) &&
              authorizedFleetAccounts.length === 1
            ) {
              fleetDropdown.disable({ emitEvent: false })
            }

            const queryParams = this.queryParamsService.get()

            let fleetAccountId = selectedFleetAccountId
            let fleetCompanyId = selectedFleetCompanyId

            if (!fleetAccountId && this._isOnPage(['services'])) {
              if (this._isOnPage(['details', 'services'])) {
                fleetAccountId = queryParams.selectedFleetAccountId
                const fleetAccount = authorizedFleetAccounts.find(
                  (fleetAccount) => fleetAccount._id === fleetAccountId
                )
                if (fleetAccount) {
                  const fleetCompany = authorizedFleetCompanies.find(
                    (fleetCompany) =>
                      fleetCompany._id === fleetAccount.fleetCompanyId
                  )
                  fleetCompanyId = fleetCompany._id
                }
              } else {
                const sortedCompanies = [...authorizedFleetCompanies].sort(
                  (a, b) => a.displayName.localeCompare(b.displayName)
                )
                const companyToBeSelected = sortedCompanies.shift()
                fleetAccountId = 'ALL'
                fleetCompanyId = companyToBeSelected._id
              }
            }

            if (
              fleetAccountId === 'ALL' &&
              this._isOnPage(['details', 'services'])
            ) {
              fleetAccountId =
                queryParams.selectedFleetAccountId || selectedFleetAccountId
            }

            if (
              !this.permissionsService.hasRole('FUEL_CONSULTANT') ||
              !ENV.production
            ) {
              const propsToOmit = this.permissionsService.hasRole(
                'FUEL_CONSULTANT'
              )
                ? ['authorizedFleetAccounts', 'authorizedFleetCompanies']
                : []
              window.analytics.page(
                'routerNavigated',
                omit(
                  {
                    customer,
                    selectedFleetAccountId: fleetAccountId,
                    selectedFleetAccountIds:
                      fleetAccountId === 'ALL'
                        ? authorizedFleetAccounts
                            .filter(
                              (fleetAccount) =>
                                fleetAccount.fleetCompanyId ===
                                selectedFleetCompanyId
                            )
                            .map((fleetAccount) => fleetAccount._id)
                        : [fleetAccountId],
                    selectedFleetCompanyId: fleetCompanyId,
                    authorizedFleetAccounts: authorizedFleetAccounts.map(
                      (fleetAccount) => fleetAccount._id
                    ),
                    authorizedFleetCompanies: authorizedFleetCompanies.map(
                      (fleetCompany) => fleetCompany._id
                    ),
                    type: 'PAGE_NAVIGATED',
                  },
                  propsToOmit
                )
              )
            }
          }
        )
      ),
    { dispatch: false }
  )

  selectFleet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          userActions.SELECT_FLEET_FROM_ORIGIN,
          userActions.SELECT_FLEET_FROM_DROPDOWN,
          userActions.SELECT_FLEET_FROM_FX,
          billingIndexActions.SELECT_FLEET_FROM_BILLING_PAGE_GUARD,
          serviceDetailsActions.SELECT_FLEET,
          billingDetailsActions.SELECT_FLEET
        ),
        withLatestFrom(this.store, (action, state) => ({
          authorizedFleetAccounts: state.user.authorizedFleetAccounts,
          authorizedFleetCompanies: state.user.authorizedFleetCompanies,
          selectedFleetAccountId: state.user.selectedFleetAccountId,
          selectedFleetCompanyId: state.user.selectedFleetCompanyId,
          customer: state.user.customer,
          isFirstChange: action.payload.firstChange,
          doNotTrack: action.payload.doNotTrack,
          preserveWithoutNavigating: action.payload.preserveWithoutNavigating,
          routerState: state.router,
        })),
        tap((trackData) => {
          let paymentProcessor = null
          let displayName = null
          let fleetAccountId = null
          let fleetCompanyId = null
          const {
            authorizedFleetAccounts,
            authorizedFleetCompanies,
            selectedFleetAccountId,
            selectedFleetCompanyId,
            isFirstChange,
            doNotTrack,
            preserveWithoutNavigating,
          } = trackData
          if (
            !this.permissionsService.hasRole('FUEL_CONSULTANT') ||
            !ENV.production
          ) {
            const propsToOmit = [
              'isFirstChange',
              'preserveWithoutNavigating',
              'routerState',
              'doNotTrack',
            ]
            if (this.permissionsService.hasRole('FUEL_CONSULTANT')) {
              propsToOmit.push(
                'authorizedFleetAccounts',
                'authorizedFleetCompanies'
              )
            }
            if (!doNotTrack) {
              window.analytics.page(
                'routerNavigated',
                omit(
                  {
                    ...trackData,
                    selectedFleetAccountIds:
                      selectedFleetAccountId === 'ALL'
                        ? authorizedFleetAccounts
                            .filter(
                              (fleetAccount) =>
                                fleetAccount.fleetCompanyId ===
                                selectedFleetCompanyId
                            )
                            .map((fleetAccount) => fleetAccount._id)
                        : [selectedFleetAccountId],
                    type: 'SELECT_FLEET_ACCOUNT',
                    authorizedFleetAccounts: authorizedFleetAccounts.map(
                      (fleetAccount) => fleetAccount._id
                    ),
                    authorizedFleetCompanies: authorizedFleetCompanies.map(
                      (fleetCompany) => fleetCompany._id
                    ),
                  },
                  propsToOmit
                )
              )
            }
          }
          const selectedDropdownItemData =
            this.sitesSelectService.selectedServiceLocation.value
          if (selectedDropdownItemData) {
            fleetAccountId = selectedDropdownItemData.fleetAccountId
            fleetCompanyId = selectedDropdownItemData.fleetCompanyId
          }
          if (
            fleetAccountId !== selectedFleetAccountId ||
            fleetCompanyId !== selectedFleetCompanyId
          ) {
            if (selectedFleetAccountId !== 'ALL') {
              const selectedFleetAccount = authorizedFleetAccounts.find(
                (account) => account._id === selectedFleetAccountId
              )

              if (selectedFleetAccount) {
                displayName = selectedFleetAccount.displayName
                paymentProcessor = selectedFleetAccount.paymentProcessor
              }
            } else {
              const selectedFleetCompany = authorizedFleetCompanies.find(
                (company) => company._id === selectedFleetCompanyId
              )
              displayName = `All sites - ${selectedFleetCompany.displayName}`
            }

            this.sitesSelectService.selectedServiceLocation.patchValue(
              {
                fleetAccountId: selectedFleetAccountId,
                fleetCompanyId: selectedFleetCompanyId,
                displayName,
                paymentProcessor,
              },
              { emitEvent: false }
            )
          }

          this.queryParamsService.setServiceLocationQueryParams(
            selectedFleetAccountId,
            selectedFleetCompanyId
          )

          setClarityCompanyIdTag(selectedFleetCompanyId)
          setClarityAccountIdTag(selectedFleetAccountId)

          // If on service details and not a first change
          if (
            this._isOnPage(['details', 'services']) &&
            !isFirstChange &&
            !preserveWithoutNavigating
          ) {
            this.router.navigate(['/services'])
          }

          // If on billing details and not a first change
          if (
            this._isOnPage(['details', 'billing']) &&
            !isFirstChange &&
            !preserveWithoutNavigating
          ) {
            this.router.navigate(['/billing'])
          }

          // If on asset details and not a first change
          if (
            this._isOnPage(['details', 'asset-management']) &&
            !isFirstChange &&
            !preserveWithoutNavigating
          ) {
            this.router.navigate(['/asset-management'])
          }
        })
      ),
    { dispatch: false }
  )

  changeFleetOrderLineItemColumns$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.CHANGE_FLEET_ORDER_LINE_ITEM_COLUMNS),
      withLatestFrom(this.store, ({ payload }, state) => ({
        fleetCompanyId: state.user.selectedFleetCompanyId,
        fleetCustomerId: payload._id,
        fleetOrderDetailsColumns: payload.fleetOrderDetailsColumns,
      })),
      exhaustMap(
        ({ fleetCustomerId, fleetOrderDetailsColumns, fleetCompanyId }) =>
          this.fleetCustomerApi
            .updateFleetOrderDetailsColumns(
              fleetCustomerId,
              fleetOrderDetailsColumns,
              fleetCompanyId
            )
            .pipe(
              map((res) =>
                userActions.CHANGE_FLEET_ORDER_LINE_ITEM_COLUMNS_SUCCESS({
                  payload: res,
                })
              ),
              catchError(() =>
                of(userActions.CHANGE_FLEET_ORDER_LINE_ITEM_COLUMNS_ERROR())
              )
            )
      )
    )
  )

  onChangeLastSeenVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.CHANGE_LAST_SEEN_VERSION),
      withLatestFrom(this.store, (action, state) => {
        const updatedLastSeenVersion = {
          projectName: PROJECT_NAME_ARMADA,
          lastSeenVersion: projectInfo.version,
        }

        return {
          _id: state.user.fleetCustomer._id,
          lastSeenVersion: updatedLastSeenVersion,
        }
      }),
      exhaustMap((updatedLastSeenVersion) =>
        this.fleetCustomerApi
          .updateLastSeenVersions(updatedLastSeenVersion)
          .pipe(
            map((res) =>
              userActions.CHANGE_LAST_SEEN_VERSION_SUCCESS({ payload: res })
            ),
            catchError(() => of(userActions.CHANGE_LAST_SEEN_VERSION_ERROR()))
          )
      )
    )
  )

  // When leaving Details pages this effect will set the header
  // Fleet Dropdown back to 'ALL', if 'ALL' was originally
  // selected before navigating to Details
  setFleetToOrigin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        serviceDetailsActions.LEAVE_PAGE,
        billingDetailsActions.LEAVE_PAGE
      ),
      withLatestFrom(this.store, (_action, state) => ({
        selectedFleetCompanyId: state.user.selectedFleetCompanyId,
        selectedFleetAccountId: state.user.selectedFleetAccountId,
        originFleetCompanyId: state.user.originFleetCompanyId,
        originFleetAccountId: state.user.originFleetAccountId,
      })),
      filter(
        ({
          selectedFleetCompanyId,
          selectedFleetAccountId,
          originFleetCompanyId,
          originFleetAccountId,
        }) =>
          originFleetCompanyId &&
          originFleetAccountId &&
          selectedFleetCompanyId === originFleetCompanyId &&
          selectedFleetAccountId !== originFleetAccountId
      ),
      map(({ originFleetCompanyId, originFleetAccountId }) =>
        userActions.SELECT_FLEET_FROM_ORIGIN(
          originFleetAccountId,
          originFleetCompanyId,
          null,
          this.sitesSelectService.isInitialSelection,
          true,
          true
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private fleetCustomerApi: FleetCustomerApi,
    private productApi: ProductApi,
    private buiModalService: BuiModalService,
    private router: Router,
    private authService: AuthService,
    private store: Store<AppStateWithUser>,
    private sitesSelectService: RmdSitesSelectService,
    private queryParamsService: QueryParamsService,
    private permissionsService: RmdPermissionsService,
    private sessionStorageService: SessionStorageService
  ) {}

  private _isOnPage(urlSegments: string[]): boolean {
    return urlSegments.every((segment) => this.router.url.includes(segment))
  }
}
