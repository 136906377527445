<div class="header" data-testid="header">
  <div class="header--small-screen">
    <rmd-logo (logoClicked)="onLogoClicked()"></rmd-logo>
    <rmd-floating-menu-toggle></rmd-floating-menu-toggle>
  </div>

  <div class="header--large-screen">
    <div class="sites-select">
      <rmd-sites-select [hidden]="!showDropdown"></rmd-sites-select>
    </div>

    <div class="header__actions-column">
      <rmd-next-service-window
        [nextServiceWindow]="nextServiceWindow"
      ></rmd-next-service-window>

      <div class="actions">
        <div class="secondary-actions-container">
          <rmd-help-button class="secondary-action"></rmd-help-button>
        </div>

        <div
          class="user-dropdown"
          *ngIf="customer"
          [buiDropdownTrigger]="dropdown"
        >
          <rmd-initials-gravatar
            [firstName]="customer.firstName"
            [lastName]="customer.lastName"
          ></rmd-initials-gravatar>
          <bui-icon name="triangle-down"></bui-icon>
          <bui-dropdown #dropdown>
            <h4 class="user-dropdown-fullname">
              {{ customer.firstName }} {{ customer.lastName }}
            </h4>
            <bui-dropdown-item [addPadding]="false">
              <a class="nav-dropdown-link" routerLink="/my-profile">
                <bui-icon name="user"></bui-icon>
                My profile
              </a>
            </bui-dropdown-item>
            <bui-dropdown-item [addPadding]="false">
              <a class="nav-dropdown-link" (click)="onLogoutClick()">
                <bui-icon name="logout"></bui-icon>
                Log out
              </a>
            </bui-dropdown-item>
          </bui-dropdown>
        </div>
      </div>
    </div>
  </div>
</div>
