import { Component, HostBinding } from '@angular/core'
import { environment as ENV } from '../../../../environments/environment'

@Component({
  selector: 'rmd-version',
  templateUrl: 'rmd-version.component.html',
  styleUrls: ['rmd-version.component.scss'],
})
export class RmdVersionComponent {
  public environment = ENV.name
  public appVersion = ENV.version
  public isProduction = ENV.production

  @HostBinding('class.rmd-version') private readonly cssClass = true
}
