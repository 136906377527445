<div class="sites-select">
  <rmd-sites-select></rmd-sites-select>
  <rmd-next-service-window
    [nextServiceWindow]="nextServiceWindow"
  ></rmd-next-service-window>
</div>

<rmd-navlist variant="floating"></rmd-navlist>

<rmd-help-contact-block></rmd-help-contact-block>

<rmd-version></rmd-version>
