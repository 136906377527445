import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, tap, exhaustMap } from 'rxjs/operators'
import { Store } from '@ngrx/store'
import { BuiToastService } from '@fleet-customer/booster-ui'

import { authActions } from './auth.actions'
import { userActions } from '../user/user.actions'
import { AppState } from '../root-reducers'

import { CustomerApi } from '../../api/customer.api'
import { AuthService } from '../../../_core/services/auth.service'
import { QueryParamsService } from '../../../_core/services/query-params.service'

@Injectable()
export class AuthEffects {
  logIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.LOGIN),
      exhaustMap(({ payload }) =>
        this.customerApi.login(payload).pipe(
          map((response) =>
            authActions.LOGIN_SUCCESS({
              ...response,
              trackingParams: payload.trackingParams,
            })
          ),
          catchError((err) => of(authActions.LOGIN_ERROR(err?.error?.error)))
        )
      )
    )
  )

  logInSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.LOGIN_SUCCESS),
        tap((data) => {
          this.authService.setAuthData(data.payload)
          this.store.dispatch(userActions.GET_FLEET_CUSTOMER_DATA())

          const { redirectUrl } = this.activatedRoute.snapshot.queryParams
          if (redirectUrl) {
            this.router
              .navigateByUrl(redirectUrl)
              .catch(() => this.router.navigate(['/']))
          } else {
            this.router.navigate(['/'])
          }
        })
      ),
    { dispatch: false }
  )

  logInError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.LOGIN_ERROR),
        tap(({ payload }) => {
          if (
            payload.status === 403 &&
            payload.message === 'PASSWORD_EXPIRED'
          ) {
            this.router.navigate(['/password-expired'])
          }
        })
      ),
    { dispatch: false }
  )

  logOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.LOGOUT),
      map(() => this.authService.getAuthToken()),
      exhaustMap((token) =>
        this.customerApi.logout(token).pipe(
          map(() => authActions.LOGOUT_SUCCESS()),
          catchError(() => of(authActions.LOGOUT_ERROR()))
        )
      )
    )
  )

  logOutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.LOGOUT_SUCCESS),
        tap(() => {
          this.queryParamsService.override({})
          this.authService.clearAuthData()
          this.router.navigate(['/login'])
        })
      ),
    { dispatch: false }
  )

  logOutError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.LOGOUT_ERROR),
        tap(() => {
          this.authService.clearAuthData()
          this.router.navigate(['/login'])
        })
      ),
    { dispatch: false }
  )

  sendPasswordResetEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.SEND_PASSWORD_RESET_EMAIL),
      exhaustMap(({ payload }) =>
        this.customerApi.sendPasswordResetEmail(payload).pipe(
          map(() => authActions.SEND_PASSWORD_RESET_EMAIL_SUCCESS()),
          catchError(({ error }) =>
            of(
              authActions.SEND_PASSWORD_RESET_EMAIL_ERROR(
                error?.error?.message ||
                  'Oops. Something went wrong. Please try again.'
              )
            )
          )
        )
      )
    )
  )

  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.RESET_PASSWORD),
      exhaustMap(({ payload }) =>
        this.customerApi.resetPassword(payload).pipe(
          map((response) => authActions.RESET_PASSWORD_SUCCESS(response)),
          catchError((err) =>
            of(authActions.RESET_PASSWORD_ERROR(err?.error?.error?.message))
          )
        )
      )
    )
  )

  resetPasswordSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.RESET_PASSWORD_SUCCESS),
        tap(() => {
          this.router.navigate(['/login'])
        })
      ),
    { dispatch: false }
  )

  checkSignUpStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.CHECK_SIGN_UP_STATUS),
      exhaustMap(({ payload }) =>
        this.customerApi.checkSignUpStatus(payload).pipe(
          map((response) => authActions.CHECK_SIGN_UP_STATUS_SUCCESS(response)),
          catchError(({ error }) =>
            of(
              authActions.CHECK_SIGN_UP_STATUS_ERROR(
                error?.error?.message ||
                  'Oops. Something went wrong. Please try again.'
              )
            )
          )
        )
      )
    )
  )

  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.SIGN_UP),
      exhaustMap(({ payload }) =>
        this.customerApi.signUp(payload).pipe(
          map((response) =>
            authActions.SIGN_UP_SUCCESS(response, payload.password)
          ),
          catchError(() => of(authActions.SIGN_UP_ERROR()))
        )
      )
    )
  )

  signUpSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.SIGN_UP_SUCCESS),
      map(({ payload }) => {
        this.buiToastService.open({
          message: 'Account created',
          variant: 'success',
        })
        return authActions.LOGIN({
          payload: {
            email: payload.response.customer.email,
            password: payload.password,
          },
        })
      })
    )
  )

  resendInviteEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.RESEND_INVITE_EMAIL),
      exhaustMap(({ payload }) =>
        this.customerApi.resendInviteEmail(payload).pipe(
          map(() => authActions.RESEND_INVITE_EMAIL_SUCCESS()),
          catchError(() => of(authActions.RESEND_INVITE_EMAIL_ERROR()))
        )
      )
    )
  )

  constructor(
    private actions$: Actions,
    private customerApi: CustomerApi,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private queryParamsService: QueryParamsService,
    private buiToastService: BuiToastService,
    private store: Store<AppState>
  ) {}
}
