import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateFn,
  CanActivateChildFn,
} from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, map, take, tap } from 'rxjs/operators'
import { AuthService } from '../auth.service'
import { AppState } from '../../root-store/root-reducers'
import { userActions } from '../../root-store/user/user.actions'
import { ErrorService } from '../error.service'
import { SessionStorageService } from '@fleet-customer/shared/frontend/storage-services'

@Injectable()
export class AuthGuard {
  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private router: Router,
    private errorService: ErrorService,
    private sessionStorageService: SessionStorageService
  ) {}

  canActivate: CanActivateFn = (route, state) => {
    return this.resolve(state, route)
  }

  canActivateChild: CanActivateChildFn = (route, state) => {
    return this.resolve(state, route)
  }

  private resolve(
    state: RouterStateSnapshot,
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | boolean {
    const accessToken: string = route.queryParams['accessToken']
    const impersonatedById: string = route.queryParams['impersonatedById']

    if (accessToken && impersonatedById) {
      this.sessionStorageService.setItem('boosterAuthToken', accessToken)
      this.sessionStorageService.setItem('impersonatedById', impersonatedById)
    }

    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/login'], {
        queryParams: {
          redirectUrl: state.url,
        },
      })
      return false
    }

    return this.store.pipe(
      select((s) => s.user.authorizedFleetAccounts),
      tap((fleetAccounts) => {
        if (fleetAccounts === null) {
          this.store.dispatch(userActions.GET_FLEET_CUSTOMER_DATA())
        }
      }),
      filter((v) => !!v),
      map((fleetAccounts) => {
        if (fleetAccounts.length === 0) {
          this.errorService.handle403()
          return false
        }

        return true
      }),
      take(1)
    )
  }
}
