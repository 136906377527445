import { Injectable } from '@angular/core'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http'

import { Observable } from 'rxjs'

import { environment as ENV } from '../../../../environments/environment'

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqWithFullUrl = req.clone({
      url: ENV.BASE_API_URL + ENV.APP_API_URL + req.url,
    })

    return next.handle(reqWithFullUrl)
  }
}
