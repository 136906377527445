import { Routes } from '@angular/router'
import { ErrorPageComponent } from './error-page.component'

const pageNotFoundDefaultData = {
  title: 'Page not found',
  message:
    'You may have mistyped the address or the page may have been moved, renamed, or it no longer exists. Try going back to the Start page.',
}

export const routes: Routes = [
  {
    path: 'forbidden-access',
    component: ErrorPageComponent,
    data: {
      showRetryLogin: true,
      title: 'Forbidden access',
      message:
        'You do not have permissions to view this account. Please contact your account manager to continue.',
    },
  },
  {
    path: 'page-not-found',
    component: ErrorPageComponent,
    data: pageNotFoundDefaultData,
  },
  {
    path: '**',
    component: ErrorPageComponent,
    data: pageNotFoundDefaultData,
  },
]
