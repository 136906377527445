import { Injectable } from '@angular/core'
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Observable } from 'rxjs'
import { filter, map, take, tap } from 'rxjs/operators'
import { selectServiceLocations } from '../../root-store/user/user.selectors'
import { get } from 'lodash'
import { billingIndexActions } from '../../../billing-page/billing-index/billing-index.actions'
import { billingDetailsActions } from '../../../billing-page/billing-details/store/billing-details.actions'
import { AppStateWithBillingDetails } from '../../../billing-page/billing-details/store/billing-details.reducer'
import { BillingDetailsComponent } from '../../../billing-page/billing-details/billing-details.component'

@Injectable()
export class BillingPageGuard {
  constructor(
    private store: Store<AppStateWithBillingDetails>,
    private router: Router
  ) {}

  canActivate: CanActivateFn = (next, _state) => {
    const isBillingDetailsPage =
      next.children[0].component === BillingDetailsComponent
    return this.store
      .select((state) => ({
        customer: state.user.customer,
        fleetCustomer: state.user.fleetCustomer,
        selectedFleetAccountId: state.user.selectedFleetAccountId,
        selectedFleetCompanyId: state.user.selectedFleetCompanyId,
        authorizedFleetAccounts: state.user.authorizedFleetAccounts,
        authorizedFleetCompanies: state.user.authorizedFleetCompanies,
        billingDetails: state.billingDetails,
        routerState: state.router,
      }))
      .pipe(
        tap(({ billingDetails: { isLoading, fleetInvoice } }) => {
          if (isBillingDetailsPage && !fleetInvoice && !isLoading) {
            const fleetInvoiceId = next.children[0].params['id']
            this.store.dispatch(
              billingDetailsActions.FIND_FLEET_INVOICE(fleetInvoiceId)
            )
          }
        }),
        filter(
          ({ billingDetails }) =>
            !isBillingDetailsPage ||
            (billingDetails !== null &&
              !billingDetails.isLoading &&
              billingDetails.fleetInvoice !== null)
        ),
        map(
          ({
            customer,
            fleetCustomer,
            selectedFleetAccountId,
            selectedFleetCompanyId,
            authorizedFleetAccounts,
            authorizedFleetCompanies,
            billingDetails,
            routerState,
          }) => {
            const fleetCompanyId = isBillingDetailsPage
              ? billingDetails.fleetInvoice.fleetCompanyId
              : selectedFleetCompanyId
            const isFuelConsultant = (customer.roles || []).includes(
              'FUEL_CONSULTANT'
            )
            const selectedCompanyFleetRole = (
              fleetCustomer.fleetRoles || []
            ).find(
              (fleetRole) =>
                fleetRole.attributes.fleetCompanyId === fleetCompanyId
            )
            const hasFleetCompanyOrAccountRole = [
              'FLEET_COMPANY_ADMIN',
              'FLEET_COMPANY_MEMBER',
              'FLEET_ACCOUNT_ADMIN',
              'FLEET_ACCOUNT_MEMBER',
            ].includes(get(selectedCompanyFleetRole, 'name', null))
            const authorizedFleetAccountsBelongingToCompany =
              authorizedFleetAccounts.filter(
                (account) => account.fleetCompanyId === fleetCompanyId
              )
            const hasPaymentProcessorNone =
              selectedFleetAccountId === 'ALL'
                ? authorizedFleetAccountsBelongingToCompany.every(
                    (fleetAccount) => fleetAccount.paymentProcessor === 'NONE'
                  )
                : get(
                    authorizedFleetAccounts.find(
                      (fleetAccount) =>
                        fleetAccount._id === selectedFleetAccountId
                    ),
                    'paymentProcessor',
                    null
                  ) === 'NONE'
            const selectedFleetCompany = authorizedFleetCompanies.find(
              (company) => company._id === fleetCompanyId
            )
            const hasBillingFeatureFlag = (
              selectedFleetCompany.clientFeatureFlags || []
            ).includes('RMD_BILLING')
            if (
              !hasPaymentProcessorNone ||
              (!isFuelConsultant && !hasBillingFeatureFlag) ||
              (!isFuelConsultant && !hasFleetCompanyOrAccountRole)
            ) {
              if (!isBillingDetailsPage) {
                const fleetCompanyToBeSelected = authorizedFleetCompanies
                  .filter(
                    (company) =>
                      isFuelConsultant ||
                      (company.clientFeatureFlags || []).includes('RMD_BILLING')
                  )
                  .sort((a, b) => a.displayName.localeCompare(b.displayName))
                  .find((company) =>
                    authorizedFleetAccounts
                      .filter(
                        (account) => account.fleetCompanyId === company._id
                      )
                      .every((account) => account.paymentProcessor === 'NONE')
                  )
                if (fleetCompanyToBeSelected) {
                  this.store.dispatch(
                    billingIndexActions.SELECT_FLEET_FROM_BILLING_PAGE_GUARD(
                      'ALL',
                      fleetCompanyToBeSelected._id,
                      null,
                      null,
                      false,
                      true
                    )
                  )
                } else {
                  this.router.navigate(['/services'])
                }
              }

              return false
            }

            return true
          }
        ),
        take(1)
      )
  }

  canActivateChild: CanActivateChildFn = () => {
    return this.resolve()
  }

  resolve(): Observable<boolean> {
    return this.store.pipe(
      select(selectServiceLocations),
      map((serviceLocations) =>
        serviceLocations.find(
          (location) => location.paymentProcessor === 'NONE'
        )
      ),
      map((sl) => !!sl),
      take(1)
    )
  }
}
