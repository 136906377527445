declare global {
  interface Window {
    clarity?: any
  }
}

interface ClarityEnvVars {
  MS_CLARITY_PROJECT_ID: string
}

export function initClarity(ENV: ClarityEnvVars) {
  if (!ENV.MS_CLARITY_PROJECT_ID) {
    return
  }

  const script = document.createElement('script')
  script.innerHTML = getClarityScript(ENV.MS_CLARITY_PROJECT_ID)
  document.head.appendChild(script)
}

function getClarityScript(projectId: string) {
  return `
    (function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${projectId}");
  `
}

function isClarityAvailable() {
  return typeof window?.clarity === 'function'
}

export const setClarityCustomUserId = (userId: string) => {
  if (!isClarityAvailable() || !userId) return

  window.clarity('identify', userId.toString())
}

export const setClarityCompanyIdTag = (fleetCompanyId: string) => {
  if (!isClarityAvailable() || !fleetCompanyId) return

  window.clarity('set', 'fleet_company_id', fleetCompanyId.toString())
}

export const setClarityAccountIdTag = (fleetAccountId: string) => {
  if (!isClarityAvailable() || !fleetAccountId) return

  window.clarity('set', 'fleet_account_id', fleetAccountId.toString())
}
