import { Component } from '@angular/core'

@Component({
  selector: 'rmd-help-contact-block',
  template: `
    <div class="contact-block">
      <h3>Need help?</h3>
      <div>
        Email
        <a href="mailto:fleetservices@boosterfuels.com">
          fleetservices&#64;boosterfuels.com
        </a>
      </div>
    </div>
  `,
  styleUrls: ['./rmd-help-contact-block.component.scss'],
})
export class RmdHelpContactBlockComponent {}
