import { Component, ChangeDetectionStrategy } from '@angular/core'
import { Store } from '@ngrx/store'
import { AppState } from '../../root-store/root-reducers'
import { selectOnboardingFleetAccounts } from '../../root-store/user/user.selectors'
import { Router } from '@angular/router'
import { map } from 'rxjs'

@Component({
  selector: 'rmd-banner-notifications',
  templateUrl: 'rmd-banner-notifications.component.html',
  styleUrls: ['rmd-banner-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmdBannerNotificationsComponent {
  public readonly onboardingFleetAccounts$ = this.store.select(
    selectOnboardingFleetAccounts
  )

  public readonly url$ = this.router.events.pipe(map(() => this.router.url))

  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {}

  public isOnPage(
    mustMatchUrlSegments: string[],
    currentUrl: string,
    mustNotMatchUrlSegments: string[] = []
  ): boolean {
    return (
      mustMatchUrlSegments.every((segment) => currentUrl.includes(segment)) &&
      mustNotMatchUrlSegments.every((segment) => !currentUrl.includes(segment))
    )
  }
}
