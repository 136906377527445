import { Injectable, Injector } from '@angular/core'
import { UrlTree, CanActivateFn } from '@angular/router'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class CombinedAsyncGuard {
  constructor(private injector: Injector) {}

  canActivate: CanActivateFn = async (route, state) => {
    const guards = route.data['guards'] || []
    for (const guard of guards) {
      const instance: {
        canActivate: CanActivateFn
      } = this.injector.get(guard)
      let result = await instance.canActivate(route, state)
      if (result instanceof Observable) {
        result = await result.toPromise()
      }
      if (result === false || result instanceof UrlTree) {
        return result
      }
    }
    return true
  }
}
