import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { ErrorPageComponent } from './error-page.component'
import { BuiButtonModule } from '@fleet-customer/booster-ui'

@NgModule({
  declarations: [ErrorPageComponent],
  imports: [CommonModule, RouterModule, BuiButtonModule],
})
export class ErrorPageModule {}
