import { AuthorizedFleetAccount } from '../../../models/customer.models'
import { AuthorizedFleetCustomer } from '../../../models/fleet-customer.models'
import { Customer, FleetCompany } from '@boosterfuels/types/types-ts'
import { userActions } from '../user.actions'
import { Action } from '@ngrx/store/src/models'

export default function determineFleetDropdownProps({
  authorizedFleetCompanies,
  authorizedFleetAccounts,
  fleetCustomer,
  customer,
  fleetAccountIdQueryParam,
  fleetCompanyIdQueryParam,
  isFirstChange,
  doNotTrack,
}: {
  authorizedFleetCompanies: Partial<FleetCompany>[]
  authorizedFleetAccounts: AuthorizedFleetAccount[]
  fleetCustomer: AuthorizedFleetCustomer
  customer: Partial<Customer>
  fleetAccountIdQueryParam: string
  fleetCompanyIdQueryParam: string
  isFirstChange: boolean
  doNotTrack: boolean
}): Action[] {
  let fleetAccountId: string = null
  let fleetCompanyId: string = null

  const sortedAuthorizedFleetCompanies = [...authorizedFleetCompanies].sort(
    (a, b) => a.displayName.localeCompare(b.displayName)
  )

  if (
    fleetAccountIdQueryParam &&
    !authorizedFleetAccounts.find(
      (account) => account._id === fleetAccountIdQueryParam
    )
  ) {
    // remove fleetAccountIdQueryParam if not in authorizedFleetAccounts
    fleetAccountIdQueryParam = null
  }

  const fleetRoles = fleetCustomer.fleetRoles.map(({ name }) => name)
  const sortedAuthorizedFleetAccounts = [...authorizedFleetAccounts].sort(
    (a, b) => a.displayName.localeCompare(b.displayName)
  )
  const isFuelConsultant = (customer.roles || []).includes('FUEL_CONSULTANT')

  if (fleetAccountIdQueryParam || fleetCompanyIdQueryParam) {
    if (
      isFuelConsultant ||
      fleetRoles.includes('FLEET_COMPANY_ADMIN') ||
      fleetRoles.includes('FLEET_COMPANY_MEMBER')
    ) {
      fleetAccountId = fleetAccountIdQueryParam || 'ALL'
    } else {
      fleetAccountId =
        fleetAccountIdQueryParam || sortedAuthorizedFleetAccounts[0]._id
    }

    if (fleetCompanyIdQueryParam) {
      fleetCompanyId = fleetCompanyIdQueryParam
    } else {
      const accountToSelect = authorizedFleetAccounts.find(
        (account) => account._id === fleetAccountId
      )
      const companyToSelect = authorizedFleetCompanies.find(
        (company) => company._id === accountToSelect.fleetCompanyId
      )
      fleetCompanyId = companyToSelect._id
    }
  } else if (isFuelConsultant) {
    fleetAccountId = 'ALL'
    fleetCompanyId = sortedAuthorizedFleetCompanies[0]._id
  } else if (
    fleetRoles.includes('FLEET_COMPANY_ADMIN') ||
    fleetRoles.includes('FLEET_COMPANY_MEMBER')
  ) {
    fleetAccountId = 'ALL'
    fleetCompanyId = findFirstFullyAuthorizedFleetCompanyId(
      sortedAuthorizedFleetCompanies,
      fleetCustomer
    )
  } else {
    fleetAccountId = sortedAuthorizedFleetAccounts[0]._id
    fleetCompanyId = sortedAuthorizedFleetAccounts[0].fleetCompanyId
  }

  return [
    userActions.SELECT_FLEET_FROM_FX(
      fleetAccountId,
      fleetCompanyId,
      null,
      isFirstChange,
      false,
      doNotTrack
    ),
    userActions.GET_PRODUCTS(),
  ]
}

function findFirstFullyAuthorizedFleetCompanyId(
  sortedAuthorizedFleetCompanies: Partial<FleetCompany>[],
  fleetCustomer: AuthorizedFleetCustomer
): string {
  let fleetCompanyId

  // for each authorized fleet company, check if has FLEET_COMPANY_* role
  for (const fleetCompany of sortedAuthorizedFleetCompanies) {
    const fleetCompanyFleetRole = fleetCustomer.fleetRoles.find(
      (fleetRole) =>
        ['FLEET_COMPANY_ADMIN', 'FLEET_COMPANY_MEMBER'].includes(
          fleetRole.name
        ) && fleetCompany._id === fleetRole.attributes.fleetCompanyId
    )

    if (fleetCompanyFleetRole) {
      fleetCompanyId = fleetCompanyFleetRole.attributes.fleetCompanyId
      break
    }
  }

  return fleetCompanyId
}
