import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LayoutComponent } from './_core/layout/layout.component'
import { routes as errorRoutes } from './error-page/error-page.routes'
import { CombinedAsyncGuard } from './_core/services/guards/combined-async-guard.service'
import { AuthGuard } from './_core/services/guards/auth-guard.service'
import { FleetRoleGuard } from './_core/services/guards/fleet-role-guard'
import { RmdFeaturesGuard } from './_core/services/guards/rmd-features-guard.service'
import { TokenGuard } from './_core/services/guards/token-guard.service'
import { BillingPageGuard } from './_core/services/guards/billing-page-guard.service'

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./login-page/login-page.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./forgot-password-page/forgot-password-page.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: 'password-expired',
    loadChildren: () =>
      import('./password-expired-page/password-expired-page.module').then(
        (m) => m.PasswordExpiredPageModule
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./reset-password-page/reset-password-page.module').then(
        (m) => m.ResetPasswordPageModule
      ),
    canActivate: [TokenGuard],
  },
  {
    path: 'sign-up',
    loadChildren: () =>
      import('./sign-up-page/sign-up-page.module').then(
        (m) => m.SignUpPageModule
      ),
  },
  {
    path: 'unsubscribe',
    loadChildren: () =>
      import('./unsubscribe/unsubscribe-page.module').then(
        (m) => m.UnsubscribePageModule
      ),
  },
  {
    // for 'service-feedback' via POST_SERVICE_EMAIL link ('fleet-post-service-email' template)
    path: 'service-feedback',
    loadChildren: () =>
      import('./service-feedback-page/service-feedback-page.module').then(
        (m) => m.ServiceFeedbackPageModule
      ),
  },
  {
    path: 'pdf',
    loadChildren: () => import('./pdf/pdf.module').then((m) => m.PdfModule),
  },
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard-page/dashboard-page.module').then(
            (m) => m.DashboardPageModule
          ),
        canActivate: [CombinedAsyncGuard],
        data: {
          guards: [AuthGuard],
        },
      },
      {
        path: 'fleet-orders',
        redirectTo: 'services',
      },
      {
        path: 'services',
        loadChildren: () =>
          import('./services-page/services-index/services-index.module').then(
            (m) => m.ServicesIndexModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'services/export-download',
        loadChildren: () =>
          import(
            './services-page/service-export-download/service-export-download.module'
          ).then((m) => m.ServiceExportDownloadModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'services/:id',
        loadChildren: () =>
          import('./services-page/service-details/service-details.module').then(
            (m) => m.ServicesDetailsModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'billing',
        loadChildren: () =>
          import('./billing-page/billing-page.module').then(
            (m) => m.BillingPageModule
          ),
        canActivate: [CombinedAsyncGuard],
        data: {
          guards: [
            AuthGuard,
            FleetRoleGuard,
            RmdFeaturesGuard,
            BillingPageGuard,
          ],
          fleetRoles: [
            'FLEET_COMPANY_ADMIN',
            'FLEET_COMPANY_MEMBER',
            'FLEET_ACCOUNT_ADMIN',
            'FLEET_ACCOUNT_MEMBER',
          ],
          features: ['RMD_PRICING', 'RMD_BILLING'],
        },
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./service-reports-page/service-reports-page.module').then(
            (m) => m.ServiceReportsPageModule
          ),
        canActivate: [CombinedAsyncGuard],
        data: {
          guards: [AuthGuard],
        },
      },
      {
        path: 'updates',
        loadChildren: () =>
          import('./updates/updates.module').then((m) => m.UpdatesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./users-page/users.module').then((m) => m.UsersModule),
        canActivate: [CombinedAsyncGuard],
        data: {
          guards: [AuthGuard, FleetRoleGuard],
          fleetRoles: [
            'FLEET_COMPANY_ADMIN',
            'FLEET_COMPANY_MEMBER',
            'FLEET_ACCOUNT_ADMIN',
            'FLEET_ACCOUNT_MEMBER',
          ],
        },
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./settings-page/settings-page.module').then(
            (m) => m.SettingsPageModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'my-profile',
        loadChildren: () =>
          import('./my-profile-page/my-profile-page.module').then(
            (m) => m.MyProfilePageModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'sites',
        loadChildren: () =>
          import('./sites-index-page/sites-index-page.module').then(
            (m) => m.SitesIndexPageModule
          ),
        canActivate: [CombinedAsyncGuard],
        data: {
          guards: [AuthGuard, FleetRoleGuard],
          fleetRoles: [
            'FLEET_COMPANY_ADMIN',
            'FLEET_COMPANY_MEMBER',
            'FLEET_ACCOUNT_ADMIN',
            'FLEET_ACCOUNT_MEMBER',
          ],
        },
      },
      {
        path: 'sites/:id',
        loadChildren: () =>
          import('./site-details-page/site-details-page.module').then(
            (m) => m.SiteDetailsPageModule
          ),
        canActivate: [CombinedAsyncGuard],
        data: {
          guards: [AuthGuard, FleetRoleGuard],
          fleetRoles: [
            'FLEET_COMPANY_ADMIN',
            'FLEET_COMPANY_MEMBER',
            'FLEET_ACCOUNT_ADMIN',
            'FLEET_ACCOUNT_MEMBER',
          ],
        },
      },
      {
        path: 'asset-management',
        loadChildren: () =>
          import(
            './asset-management-page/asset-management-index/asset-management-index.module'
          ).then((m) => m.AssetManagementIndexModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'asset-management/:id',
        loadChildren: () =>
          import(
            './asset-management-page/asset-management-details/asset-management-details.module'
          ).then((m) => m.AssetManagementDetailsModule),
        canActivate: [AuthGuard],
      },
    ],
  },
  ...errorRoutes,
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
