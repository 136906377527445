import { HTTP_INTERCEPTORS } from '@angular/common/http'

import { AuthInterceptor } from './auth.interceptor'
import { UrlInterceptor } from './url.interceptor'
import { GetParamsEncoderInterceptor } from './get-params-encoder.interceptor'
import { ErrorInterceptor } from './error.interceptor'

// Http interceptor providers in outside-in order
// server -> interceptor[0] -> interceptor[1] -> apiService
// apiService -> interceptor[1] -> interceptor[0] -> server
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: GetParamsEncoderInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
]
