import { Injectable } from '@angular/core'
import { Router, CanActivateFn, CanActivateChildFn } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { AppState } from '../../root-store/root-reducers'
import { selectPermissionsData } from '../../root-store/user/user.selectors'
import { Observable } from 'rxjs'
import { take, map } from 'rxjs/operators'
import { get } from 'lodash'

@Injectable()
export class FleetRoleGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {}

  canActivate: CanActivateFn = (route, state) => {
    const fleetRoles = get(route, 'data.fleetRoles')
    return this.resolve(fleetRoles)
  }

  canActivateChild: CanActivateChildFn = (route, state) => {
    const fleetRoles = get(route, 'data.fleetRoles')
    return this.resolve(fleetRoles)
  }

  resolve(fleetRoles: string[]): Observable<boolean> | boolean {
    return this.store.pipe(
      select(selectPermissionsData),
      map(
        ({ fleetRole, isFuelConsultant }) =>
          isFuelConsultant || fleetRoles.includes(get(fleetRole, 'name'))
      ),
      take(1)
    )
  }
}
