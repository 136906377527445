import { Injectable } from '@angular/core'
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class ProductApi {
  constructor(private http: HttpClient) {}

  find(): Observable<any> {
    let params = new HttpParams()
    params = params.append('fuelType', JSON.stringify({ $exists: true }))
    params = params.append('limit', '50')

    return this.http.get<any>('/Product', { params })
  }
}
