import * as Sentry from '@sentry/angular-ivy'

const isProdLikeEnv = {
  production: true,
  staging: true,
} as any

export function initSentry(ENV: any) {
  if (!isProdLikeEnv[ENV.name]) {
    return
  }

  Sentry.init({
    dsn: ENV.SENTRY_DSN,
    environment: ENV.name,
    release: ENV.version,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
    beforeSend,
    beforeBreadcrumb,
  })
}

function beforeSend(event: Sentry.Event, hint: Sentry.EventHint) {
  // filter out UnhandledRejection errors that have no information
  if (event?.exception?.values?.[0]) {
    const e = event.exception.values[0]

    if (
      e.type === 'UnhandledRejection' &&
      e.value === 'Non-Error promise rejection captured with value: '
    ) {
      return null
    }
  }

  // filter out ChunkLoadError errors because there's nothing we can do about them at the moment
  if (event?.exception?.values?.[0]?.value?.includes('ChunkLoadError')) {
    return null
  }

  return event
}

function beforeBreadcrumb(
  breadcrumb: Sentry.Breadcrumb,
  hint?: Sentry.BreadcrumbHint
): Sentry.Breadcrumb | null {
  if (isSegmentBreadcrumb(breadcrumb) || isClarityBreadcrumb(breadcrumb)) {
    return null
  }

  return breadcrumb
}

function isClarityBreadcrumb(breadcrumb: Sentry.Breadcrumb): boolean {
  const url = breadcrumb?.data?.['url'] || ''
  return breadcrumb.category === 'xhr' && url.includes('clarity.ms')
}

function isSegmentBreadcrumb(breadcrumb: Sentry.Breadcrumb): boolean {
  const url = breadcrumb?.data?.['url'] || ''
  return (
    breadcrumb.category === 'fetch' &&
    (url.includes('api.segment.io') || url.includes('cdn.segment.com'))
  )
}
