import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { initMouseMovementDetector } from './assets/lib/mouseMovementDetector'

import {
  initClarity,
  initSegment,
  initSentry,
} from '@fleet-customer/shared/frontend/third-party-scripts'
import { initGoogleAnalytics } from './assets/lib/googleAnalytics'

import { AppModule } from './app/app.module'

import { environment as ENV } from './environments/environment'

if (ENV.production) {
  enableProdMode()
  initGoogleAnalytics()
  initClarity(ENV)
}

initSentry(ENV)

initMouseMovementDetector()
initSegment(ENV)

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
