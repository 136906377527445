import { Injectable } from '@angular/core'
import { Router, CanActivateFn } from '@angular/router'
import { Store } from '@ngrx/store'
import { AppState } from '../../root-store/root-reducers'
import { Observable } from 'rxjs'

@Injectable()
export class TokenGuard {
  constructor(
    private store: Store<AppState>,
    private router: Router
  ) {}

  canActivate: CanActivateFn = (route, state) => {
    const { token } = route.queryParams
    if (token) {
      return true
    } else {
      this.router.navigate(['/login'])
      return false
    }
  }
}
