<ng-container
  [ngSwitch]="getHeaderLayout()"
  *ngIf="sitesSelectService.serviceLocations"
>
  <rmd-sites-select-multi-company
    *ngSwitchCase="TEMPLATE.MultiCompany"
    [sitesSelectService]="sitesSelectService"
  >
  </rmd-sites-select-multi-company>

  <rmd-sites-select-multi-account
    *ngSwitchCase="TEMPLATE.MultiAccount"
    [sitesSelectService]="sitesSelectService"
  ></rmd-sites-select-multi-account>

  <rmd-sites-select-single-account
    *ngSwitchCase="TEMPLATE.SingleAccount"
    [siteName]="sitesSelectService.serviceLocations[0].displayName"
  >
  </rmd-sites-select-single-account>
</ng-container>
