import { ActionReducer } from '@ngrx/store'
import { authActions } from '../auth/auth.actions'

export function clearStateOnLogout(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return function (state, action) {
    if (
      action.type === authActions.LOGOUT_SUCCESS.type ||
      action.type === authActions.LOGOUT_ERROR.type
    ) {
      state = void 0
    }

    return reducer(state, action)
  }
}
