import { Component, ChangeDetectionStrategy } from '@angular/core'

import { RmdSitesSelectService } from './rmd-sites-select.service'

enum SitesSelectTemplate {
  MultiCompany = 'multi-company', // Multiple companies, one or more accounts
  MultiAccount = 'multi-account', // One company, multiple accounts
  SingleAccount = 'single-account', // One company, one account
}

@Component({
  selector: 'rmd-sites-select',
  templateUrl: 'rmd-sites-select.component.html',
  styleUrls: ['rmd-sites-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmdSitesSelectComponent {
  public TEMPLATE = SitesSelectTemplate

  constructor(public sitesSelectService: RmdSitesSelectService) {}

  public getHeaderLayout() {
    // return this.TEMPLATE.MultiCompany
    return (this.sitesSelectService.serviceLocations || []).length > 1
      ? this.TEMPLATE.MultiAccount
      : this.TEMPLATE.SingleAccount
  }
}
