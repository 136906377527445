import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

@Component({
  selector: 'rmd-sites-select-single-account',
  templateUrl: './rmd-sites-select-single-account.component.html',
  styleUrls: ['./rmd-sites-select-single-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RmdSitesSelectSingleAccountComponent {
  @Input() siteName: string
}
