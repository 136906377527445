import { createReducer, on } from '@ngrx/store'
import { cloneDeep, get } from 'lodash'
import { FleetCompany, Customer, Product } from '@boosterfuels/types/types-ts'

import { AppState } from '../../root-store/root-reducers'
import { AuthorizedFleetAccount } from '../../models/customer.models'
import {
  FleetRole,
  AuthorizedFleetCustomer,
} from '../../models/fleet-customer.models'

import { userActions } from './user.actions'
import { serviceDetailsActions } from '../../../services-page/service-details/store/service-details.actions'
import { billingIndexActions } from '../../../billing-page/billing-index/billing-index.actions'
import { billingDetailsActions } from '../../../billing-page/billing-details/store/billing-details.actions'
import { Update } from '../../../_core/models/update.models'

import updates from '../../../../assets/updates/updates.json'
import { PROJECT_NAME_ARMADA } from '@fleet-customer/shared/constants'
import { routerNavigationAction } from '@ngrx/router-store'
import { hideDropdownForPages } from './util/hideDropdownForPages'
import { siteContactsTabActions } from '../../../site-details-page/site-contacts-tab/store/site-contacts-tab.actions'
import { siteSettingsTabActions } from '../../../site-details-page/site-settings-tab/store/site-settings-tab.actions'
import { companySettingsTabActions } from '../../../settings-page/company-settings-tab/store/company-settings-tab.actions'

export interface State {
  products: Product[]
  authorizedFleetAccounts: AuthorizedFleetAccount[]
  disabledAuthorizedFleetAccounts: AuthorizedFleetAccount[]
  authorizedFleetCompanies: Partial<FleetCompany>[]
  originFleetCompanyId: string
  originFleetAccountId: string
  selectedFleetAccountId: string
  selectedFleetCompanyId: string

  showDropdown: boolean

  isFleetCustomerLoading: boolean
  fleetCustomer: AuthorizedFleetCustomer
  customer: Partial<Customer>
  fleetRoles: FleetRole[]
  isFuelConsultant: boolean
  submittedFeatureFeedbacks: Array<string>

  updates: Update[]
}

export const initialState: State = {
  products: null,
  authorizedFleetAccounts: null,
  disabledAuthorizedFleetAccounts: null,
  authorizedFleetCompanies: null,
  originFleetCompanyId: null,
  originFleetAccountId: null,
  selectedFleetAccountId: null,
  selectedFleetCompanyId: null,

  showDropdown: true,

  isFleetCustomerLoading: false,
  fleetCustomer: null,
  customer: null,
  fleetRoles: null,
  isFuelConsultant: null,
  submittedFeatureFeedbacks: [],

  updates: [],
}

export const reducer = createReducer(
  initialState,

  on(routerNavigationAction, (state, { payload }) => {
    // remove query params
    const [url] = payload.routerState.url.split('?')
    const hideDropdown = hideDropdownForPages.some((pageRegex) =>
      pageRegex.test(url)
    )

    return { ...state, showDropdown: !hideDropdown }
  }),

  on(
    userActions.SELECT_FLEET_FROM_ORIGIN,
    userActions.SELECT_FLEET_FROM_DROPDOWN,
    userActions.SELECT_FLEET_FROM_FX,
    billingIndexActions.SELECT_FLEET_FROM_BILLING_PAGE_GUARD,
    serviceDetailsActions.SELECT_FLEET,
    billingDetailsActions.SELECT_FLEET,
    siteContactsTabActions.SELECT_FLEET,
    (state, { payload }) => ({
      ...state,
      selectedFleetAccountId: payload.fleetAccountId,
      selectedFleetCompanyId: payload.fleetCompanyId,
    })
  ),

  on(
    companySettingsTabActions.UPDATE_START_OF_WEEK_PREFERENCES_SUCCESS,
    companySettingsTabActions.UPDATE_FISCAL_YEAR_START_MONTH_PREFERENCES_SUCCESS,
    (state, { payload }) => {
      const authorizedFleetCompanies = cloneDeep(state.authorizedFleetCompanies)
      const updatedFleetCompanyIndex = authorizedFleetCompanies.findIndex(
        (c) => c?._id === payload?.fleetCompany?._id
      )

      if (updatedFleetCompanyIndex !== -1) {
        authorizedFleetCompanies[updatedFleetCompanyIndex].weekStartsOnSunday =
          payload?.fleetCompany?.weekStartsOnSunday
        authorizedFleetCompanies[
          updatedFleetCompanyIndex
        ].fiscalYearStartMonth = payload?.fleetCompany?.fiscalYearStartMonth
      }

      return {
        ...state,
        authorizedFleetCompanies: [...authorizedFleetCompanies],
      }
    }
  ),

  on(
    siteSettingsTabActions.UPDATE_COST_SAVINGS_DATA_BY_DATE_SUCCESS,
    (state, { payload }) => {
      const { updatedFleetAccounts } = payload
      const authorizedFleetAccounts = cloneDeep(state.authorizedFleetAccounts)

      for (const updatedFleetAccount of updatedFleetAccounts) {
        const updatedFleetAccountIndex = authorizedFleetAccounts.findIndex(
          (c) => c?._id === updatedFleetAccount._id
        )

        if (updatedFleetAccountIndex !== -1) {
          authorizedFleetAccounts[updatedFleetAccountIndex][
            'costSavingsDataByDate'
          ] = updatedFleetAccount.costSavingsDataByDate
        }
      }

      return {
        ...state,
        authorizedFleetAccounts: [...authorizedFleetAccounts],
      }
    }
  ),

  on(
    userActions.SELECT_FLEET_FROM_ORIGIN,
    userActions.SELECT_FLEET_FROM_DROPDOWN,
    (state) => ({
      ...state,
      originFleetCompanyId: null,
      originFleetAccountId: null,
    })
  ),

  on(userActions.GET_PRODUCTS_SUCCESS, (state, { payload }) => ({
    ...state,
    products: payload,
  })),

  on(userActions.GET_FLEET_CUSTOMER_DATA, (state) => ({
    ...state,
    isFleetCustomerLoading: true,
  })),

  on(userActions.GET_FLEET_CUSTOMER_DATA_ERROR, (state) => ({
    ...state,
    isFleetCustomerLoading: false,
  })),

  on(
    userActions.GET_FLEET_CUSTOMER_DATA_SUCCESS,
    (
      state,
      {
        fleetCustomer,
        customer,
        isFuelConsultant,
        authorizedFleetAccounts,
        disabledAuthorizedFleetAccounts,
        authorizedFleetCompanies,
        submittedFeatureFeedbacks,
      }
    ) => ({
      ...state,
      authorizedFleetAccounts,
      disabledAuthorizedFleetAccounts,
      authorizedFleetCompanies,
      selectedFleetCompanyId: null,
      selectedFleetAccountId: null,
      isFleetCustomerLoading: false,
      fleetCustomer: {
        ...state.fleetCustomer,
        ...fleetCustomer,
      },
      customer: {
        ...state.customer,
        ...customer,
      },
      fleetRoles: get(fleetCustomer, 'fleetRoles', []),
      isFuelConsultant,
      submittedFeatureFeedbacks,
      updates: updates
        .filter((update) => {
          const featureFlags = [
            ...new Set(
              ...authorizedFleetCompanies.map(
                (company) => company.clientFeatureFlags
              )
            ),
          ]

          return (
            !update.features ||
            isFuelConsultant ||
            update.features.every((feature) =>
              (featureFlags || []).includes(feature)
            )
          )
        })
        .map((update) => {
          const projectVersionData = (
            fleetCustomer.lastSeenVersions || []
          ).find(
            (versionData) => versionData.projectName === PROJECT_NAME_ARMADA
          )
          const lastSeenVersion = projectVersionData
            ? projectVersionData.lastSeenVersion
            : '0.0.0'

          return { ...update, isNew: update.releaseVersion > lastSeenVersion }
        })
        .reverse(),
    })
  ),

  on(
    userActions.CHANGE_FLEET_ORDER_LINE_ITEM_COLUMNS_SUCCESS,
    (state, { payload }) => ({
      ...state,
      fleetCustomer: {
        ...state.fleetCustomer,
        ...payload.fleetCustomer,
      },
    })
  ),

  on(userActions.CHANGE_LAST_SEEN_VERSION_SUCCESS, (state, { payload }) => ({
    ...state,
    fleetCustomer: {
      ...state.fleetCustomer,
      lastSeenVersions: payload.fleetCustomer.lastSeenVersions,
    },
  })),

  on(
    serviceDetailsActions.CREATE_MAP_FEATURE_FEEDBACK_SUCCESS,
    (state, { payload }) => ({
      ...state,
      submittedFeatureFeedbacks: [
        ...state.submittedFeatureFeedbacks,
        payload.featureName,
      ],
    })
  ),

  on(
    serviceDetailsActions.SET_ORIGIN_FLEET,
    billingDetailsActions.SET_ORIGIN_FLEET,
    (state) => ({
      ...state,
      originFleetCompanyId: state.selectedFleetCompanyId,
      originFleetAccountId: state.selectedFleetAccountId,
    })
  )
)

export interface AppStateWithUser extends AppState {
  user: State
}
