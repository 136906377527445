import { Injectable } from '@angular/core'

import { isLocalEnvironment } from '../../../_shared/helpers/environment.helper'
import { CanActivateChildFn, CanActivateFn } from '@angular/router'

@Injectable()
export class LocalEnvironmentGuard {
  canActivate: CanActivateFn = () => {
    return this.resolve()
  }

  canActivateChild: CanActivateChildFn = () => {
    return this.resolve()
  }

  resolve(): boolean {
    return isLocalEnvironment
  }
}
