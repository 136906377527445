import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { CommonModule } from '@angular/common'
import { LayoutComponent } from './layout.component'
import { RmdSidebarComponent } from './rmd-sidebar/rmd-sidebar.component'
import { RmdHeaderComponent } from './rmd-header/rmd-header.component'
import { RmdSitesSelectComponent } from './rmd-sites-select/rmd-sites-select.component'
import { ReactiveFormsModule } from '@angular/forms'
import { RmdFloatingMenuService } from './rmd-floating-menu/rmd-floating-menu.service'
import { RmdFloatingMenuComponent } from './rmd-floating-menu/rmd-floating-menu.component'
import { RmdFloatingMenuToggleComponent } from './rmd-floating-menu-toggle/rmd-floating-menu-toggle.component'
import { RmdSitesSelectService } from './rmd-sites-select/rmd-sites-select.service'
import {
  BuiButtonModule,
  BuiDropdownModule,
  BuiIconModule,
  BuiMessageModule,
  BuiNavlistModule,
  BuiSelectModule,
} from '@fleet-customer/booster-ui'
import { RmdVersionComponent } from './rmd-version/rmd-version.component'
import { RmdInitialsGravatarModule } from '../../_shared/modules/rmd-initials-gravatar/rmd-initials-gravatar.module'
import { RmdNextServiceWindowComponent } from './rmd-next-service-window/rmd-next-service-window.component'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { RmdLogoModule } from '../../_shared/modules/rmd-logo/rmd-logo.module'
import { RmdHelpButtonComponent } from './rmd-help-button/rmd-help-button.component'
import { RmdHelpContactBlockComponent } from './rmd-help-button/rmd-help-contact-block.component'
import { RmdNavlistComponent } from './rmd-navlist/rmd-navlist.component'
import { RmdBannerNotificationsComponent } from './rmd-banner-notification/rmd-banner-notifications.component'
import { LetDirective } from '@ngrx/component'
import { RmdSitesSelectMultiAccountComponent } from './rmd-sites-select/rmd-sites-select-multi-account/rmd-sites-select-multi-account.component'
import { RmdSitesSelectSingleAccountComponent } from './rmd-sites-select/rmd-sites-select-single-account/rmd-sites-select-single-account.component'
import { RmdSitesSelectMultiCompanyComponent } from './rmd-sites-select/rmd-sites-select-multi-company/rmd-sites-select-multi-company.component'

@NgModule({
  declarations: [
    LayoutComponent,
    RmdHeaderComponent,
    RmdSidebarComponent,
    RmdNavlistComponent,
    RmdFloatingMenuComponent,
    RmdFloatingMenuToggleComponent,
    RmdSitesSelectComponent,
    RmdVersionComponent,
    RmdNextServiceWindowComponent,
    RmdHelpButtonComponent,
    RmdHelpContactBlockComponent,
    RmdBannerNotificationsComponent,
    RmdSitesSelectMultiAccountComponent,
    RmdSitesSelectSingleAccountComponent,
    RmdSitesSelectMultiCompanyComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ScrollingModule,
    LetDirective,
    BuiButtonModule,
    BuiDropdownModule,
    BuiMessageModule,
    BuiIconModule,
    BuiNavlistModule,
    BuiSelectModule,
    RmdInitialsGravatarModule,
    RmdLogoModule,
  ],
  providers: [RmdFloatingMenuService, RmdSitesSelectService],
  exports: [LayoutComponent],
})
export class LayoutModule {}
