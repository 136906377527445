import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Store, select } from '@ngrx/store'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http'

import { AppStateWithUser } from '../../../_core/root-store/user/user.reducer'
import { AuthService } from '../../services/auth.service'
import { switchMap, take } from 'rxjs/operators'

interface RequestUpdateParams {
  setHeaders: {
    Authorization: string
  }
  setParams: {
    fleetCompanyId: string
    impersonatedById?: string
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private store: Store<AppStateWithUser>
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.pipe(
      select((s) => s.user.selectedFleetCompanyId),
      take(1),
      switchMap((fleetCompanyId) => {
        const authToken = this.authService.getAuthToken()
        const impersonatedById = this.authService.getImpersonatedById()

        const requestUpdateParams: RequestUpdateParams = {
          setHeaders: {
            Authorization: authToken,
          },
          setParams: {
            fleetCompanyId,
          },
        }

        if (impersonatedById) {
          requestUpdateParams.setParams.impersonatedById = impersonatedById
        }

        if (authToken) {
          const authReq = req.clone(requestUpdateParams)

          return next.handle(authReq)
        }

        return next.handle(req)
      })
    )
  }
}
